* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
.highlight {
  color: #2d80d3; /* Highlight color */
}
.home,
.aboutme,
.resume,
.contact {
  display: flex;
  height: 140vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
/* ABOUT ME SECTION */
p3 {
  font-size: 1.3rem;
}
.aboutme-container {
  color:#fff;
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
  gap: 2%; /* Space between text and image */
  padding: 5px; /* Optional: Add padding around the container */
  margin: 1%;
  margin-bottom: 0%;
  flex-wrap: wrap;
}
.aboutme-container2 {
  color:#fff;
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
  flex-wrap: wrap;
}
.firstContainerEnd {
  margin-bottom: 0%;
}
.text-container {
  flex: 1; /* Take up remaining space */
  font-size: large;
}

.about-photo img {
  max-width: 60%; /* Ensures the image fits within its container */
  width: 600px; /* Adjust this value as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Adds rounded corners to the image */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow */
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  margin-left: 35%;
}
.header {
  margin-bottom: 3%;
  margin-left: 25%;
}
.header,
.header2,
.header3 {
  display: flex;
  align-items: center;
  justify-content: left;
}
.header3 {
  margin-right: 5%;
  justify-content: center;
  margin-bottom: 3%;
  margin-top: 3%;
}
.header2 {
  margin-right: 10%;
  justify-content: center;
  margin-bottom: 3%;
  margin-top: 3%;
}
.header-body,
.header2-body,
.header3-body,
.coding-languages-header,
.research-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;

}
.coding-languages-header {
  margin-bottom: 7%;
}
.coding-language-container {
  padding: 5px;
  margin-left: 10%;
}
.tech-icons {
  font-size: 1.5em;
  margin: 6px !important;
  margin-right:3% !important;
  padding: 5px !important;
  opacity: 0.93 !important;
  border: 3px solid #2d80d3 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 100px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  color: white;
}
.tech-icons2 {
  font-size: 1em;
  margin: 5px !important;
  padding: 5px !important;
  opacity: 0.93 !important;
  border: 3px solid #2d80d3 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 100px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 1.2px solid rgba(197, 115, 230, 0.883) !important;
}
/* Media Queries for Responsive Layout */
@media (max-width: 768px) {
  .aboutme-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }
  p3 {
    font-size: 1rem;

  }
  h2 {
    font-size: 2rem;
    margin-left: 10%;
  }
  .header {
    margin-left: 2%;
  }
  
  .text-container {
    width: 100%; /* Full width for text */
    text-align: center; /* Center text on smaller screens */
  }

  .about-photo img {
    width: 100%; /* Full width for image */
    max-width: 300px; /* Limit max width */
    margin: 20px 0; /* Add margin for spacing */
  }
}

.research-container {
  margin: 3%
}

/* RESUME SECTION */
.resume-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensures the container takes at least the full height of the viewport */
  padding: 20px;
  text-align: center;
  overflow: hidden; /* Ensure the particles don't overflow */
}

.resume-pdf {
  margin-top: 20px;
  background: white; /* Ensures the PDF area has a white background */
  padding: 20px; /* Optional: Padding around the PDF */
  border-radius: 10px; /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow for depth */
  z-index: 1; /* Ensure it is above the Particle component */
}

.resume-desc {
  text-align: center;
  margin-bottom: 20px;
  z-index: 1; /* Ensure it is above the Particle component */
}

.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it is behind the resume content */
}
.res-btn {
  color:black;
  margin-top: 20px;
  
}

/* CONTACT SECTION */
.contactme-header {
  font-size: 4rem;
}
.contact-container {
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-box {
  background: #2d80d3;
  padding: 3%;
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 65%;
  width: 100%;
  display:flex;
  align-items: center;
  margin-top: 2%;
}

.contact-photo {
  width: 225px; /* Adjust the size as needed */
  height: auto;
  border-radius: 60%; /* Adjust border radius as needed */
  margin-right: 8%; /* Add some spacing */
  margin-left: 2%;
}

.contact-content {
  display:contents;
  flex-direction: column;
  align-items: right; /* Align items to the start */
  justify-content: center;
}

.contact-details {
  text-align: left; /* Align the text to the left */
  color: #fff;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  display:grid;
  margin-bottom: 1%;
  margin-right: 2%;
}
p4 {
  margin: 3%;
  margin-left: 2%;

}



/* Responsive Design */
/* Media query for smaller screens */
@media (max-width: 600px) {
  .contact-photo {
    width: 200px; /* Reduce the size of the photo */
    margin-right: 10px; /* Adjust spacing */
  }

  .contact-box {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center;
    max-width: 80%;
  }

  .contact-content {
    align-items: center; /* Center align content on smaller screens */
    text-align: center; /* Center text alignment */
  }
  .contact-details {
    text-align: left; /* Align the text to the left */
    color: #fff;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    display:grid;
  }
}
