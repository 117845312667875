.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.btn-cont {
  margin-top: 15px;
  margin-left: -20px;
  margin-right: -3px;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: left;
  height: 5rem;
  max-width: 100%;
}
.navbar-logo-img {
  position: absolute;
  top: 2rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
  justify-self: start;
  margin-bottom: 80%;
  height: 40%;


}
.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: left;
  margin-top: 2%;
  margin-left:0%
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-right: 2rem;
  margin-left:14rem;
  align-items:center;
  margin-top: 0.8rem;
}

.nav-item {
  display: inline-flex;
  align-items: center;
}

.nav-links {
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}


.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
    height: 70vh;
    position: absolute;
    top:80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #222224;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo-img {
    position: absolute;
    top: 0;
    left: -2rem;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 80px;
    width: 100%;
    text-decoration: none;
    font-size: 1.2rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
